/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
} from '@mui/material';
import Loader from '../Loader';
import moment from 'moment/moment';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { generateTimeSlots } from '../../api';

// Styled components
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  padding: '8px',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  fontSize: '1rem',
  backgroundColor: 'white',
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}20`,
  },
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledSelect = styled('select')(({ theme }) => ({
  width: '100%',
  padding: '8px',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  fontSize: '1rem',
  backgroundColor: 'white',
  height: '38px',
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}20`,
  },
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

function AssessorModal(props) {
  const [loading, setLoading] = useState(false);
  const [valueFrom, setValueFrom] = useState('');
  const [valueTo, setValueTo] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [config, setConfig] = useState({
    start_time: '09:00',
    end_time: '17:00',
    slot_duration: 60,
    break_duration: 60,
    slots_before_break: 3
  });

  const minDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
  const maxDate = moment().add(1, 'year').format('YYYY-MM-DD');

  // Generate time slots for a given date
  const generateTimeSlotsCalculation = (date) => {
    const slots = [];
    const currentTime = moment(`${date} ${config.start_time}`);
    const endTime = moment(`${date} ${config.end_time}`);
    let slotCount = 0;
    let isBreak = false;

    while (currentTime.isBefore(endTime)) {
      const slotStartTime = currentTime.format('HH:mm');

      if (!isBreak) {
        // Add slot duration
        currentTime.add(parseInt(config.slot_duration), 'minutes');
        // Don't add slot if it would exceed end time
        if (currentTime.isAfter(endTime)) break;
        const slotEndTime = currentTime.format('HH:mm');
        slots.push({
          type: 'slot',
          start: slotStartTime,
          end: slotEndTime,
          date
        });

        slotCount++;

        // Check if we need to switch to break
        if (slotCount >= parseInt(config.slots_before_break)) {
          isBreak = true;
          slotCount = 0;
        }
      } else {
        // Add break duration
        currentTime.add(parseInt(config.break_duration), 'minutes');
        // Don't add break if it would exceed end time
        if (currentTime.isAfter(endTime)) break;
        const breakEndTime = currentTime.format('HH:mm');
        slots.push({
          type: 'break',
          start: slotStartTime,
          end: breakEndTime,
          date
        });

        // Switch back to slots after break
        isBreak = false;
      }
    }

    return slots;
  };

  // Generate array of dates and their time slots between selected dates
  useEffect(() => {
    if (valueFrom && valueTo) {
      const dates = [];
      let currentDate = moment(valueFrom);
      const endDate = moment(valueTo);

      while (currentDate.isSameOrBefore(endDate)) {
        const formattedDate = currentDate.format('YYYY-MM-DD');
        const dailySlots = generateTimeSlotsCalculation(formattedDate);
        dates.push({
          date: formattedDate,
          slots: dailySlots
        });
        currentDate = moment(currentDate).add(1, 'days');
      }
      setDateRange(dates);
      console.log('Date range with time slots:', dates);
    } else {
      setDateRange([]);
    }
  }, [valueFrom, valueTo, config]);

  const handleFromDateChange = (event) => {
    setValueFrom(event.target.value);
  };

  const handleToDateChange = (event) => {
    setValueTo(event.target.value);
  };

  const handleConfigChange = (field) => (event) => {
    setConfig(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSubmit = async () => {
    if (valueFrom && valueTo) {
      const dates = [];
      let currentDate = moment(valueFrom);
      const endDate = moment(valueTo);

      while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
      }

      const payload = {
        dates,
        ...config
      };

      const response = await generateTimeSlots(payload);

      console.log(response);
      // onclose();
    }
  };

  console.log(dateRange);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            display="flex"
            gap={3}
            sx={{ p: 3 }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              color="primary"
              sx={{ mb: 2 }}
            >
              Slot Configuration
            </Typography>

            {/* Date Selection */}
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>From Date</Typography>
                <StyledInput
                  type="date"
                  aria-label="Date from"
                  max={valueTo || maxDate}
                  min={minDate}
                  value={valueFrom}
                  onChange={handleFromDateChange}
                />
              </Box>
              <Box>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>To Date</Typography>
                <StyledInput
                  type="date"
                  aria-label="Date to"
                  max={maxDate}
                  min={valueFrom || minDate}
                  value={valueTo}
                  onChange={handleToDateChange}
                />
              </Box>
            </Box>

            {/* Time Configuration */}
            <Grid container spacing={'20px'} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Start Time</Typography>
                <StyledInput
                  type="time"
                  value={config.start_time}
                  onChange={handleConfigChange('start_time')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">End Time</Typography>
                <StyledInput
                  type="time"
                  value={config.end_time}
                  onChange={handleConfigChange('end_time')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Slot Duration (minutes)</Typography>
                <StyledSelect
                  value={config.slot_duration}
                  onChange={handleConfigChange('slot_duration')}
                >
                  <option value={30}>30 minutes</option>
                  <option value={60}>60 minutes</option>
                  <option value={90}>90 minutes</option>
                </StyledSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Break Duration (minutes)</Typography>
                <StyledSelect
                  value={config.break_duration}
                  onChange={handleConfigChange('break_duration')}
                >
                  <option value={15}>15 minutes</option>
                  <option value={30}>30 minutes</option>
                  <option value={60}>60 minutes</option>
                  <option value={90}>90 minutes</option>
                  <option value={120}>120 minutes</option>
                </StyledSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Slots Before Break</Typography>
                <StyledInput
                  type="number"
                  min="1"
                  max="10"
                  value={config.slots_before_break}
                  onChange={handleConfigChange('slots_before_break')}
                />
              </Grid>
            </Grid>

            {/* Preview Section */}
            {dateRange.length > 0 && (
              <Box sx={{ width: '100%', mt: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Preview</Typography>
                {dateRange.map((day) => (
                  <Accordion key={day.date}>
                    <AccordionSummary expandIcon={<ExpandCircleDownOutlined />}>
                      <Typography>{moment(day.date).format('dddd, MMMM D, YYYY')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1}>
                        {day.slots.map((slot, index) => (
                          <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                              sx={{
                                p: 1,
                                border: 1,
                                borderColor: slot.type === 'break' ? 'warning.main' : 'primary.main',
                                borderRadius: 1,
                                bgcolor: slot.type === 'break' ? 'warning.light' : 'primary.light',
                                color: slot.type === 'break' ? 'warning.dark' : 'primary.dark',
                              }}
                            >
                              <Typography variant="body2">
                                {slot.type === 'break' ? 'Break: ' : 'Slot: '}
                                {slot.start} - {slot.end}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}

            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!valueFrom || !valueTo}
              sx={{ mt: 2 }}
            >
              Save Configuration
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AssessorModal;
