import axios from 'axios';

export async function exportUserData (param) {
  try {
    const response = await axios.get(`/admin/users/${param}/export`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to process this export. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function exportAdminData (param) {
  try {
    const response = await axios.get(`/admin/${param}/export`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to process this export. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function exportTransactionsData (param) {
  try {
    const response = await axios.get(
      `/admin/${param}/export?status=SUCCESSFUL`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to process this export. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}
