import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Button, Alert, Typography } from '@mui/material';
import { createUpskill, updateUpskill } from '../../api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Loader from '../Loader';

export default function Index () {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [upskillData, setUpskillData] = useState();
  const [errorMessage, setErrorMessage] = useState([]);
  const [pageMessage, setPageMessage] = useState('');

  const artisanData = localStorage.getItem(`artisan_${id}`);
  const storedAssessment = localStorage.getItem(`upskill_${id}`);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    let response;
    if (!upskillData) {
      response = await createUpskill({
        ...data,
        artisan_id: id,
        upskilling_date: moment(data.dateInput).format('YYYY-MM-DD HH:mm:ss')
      });
    } else {
      response = await updateUpskill(
        {
          ...data,
          artisan_id: id,
          upskilling_date: moment(data.dateInput).format('YYYY-MM-DD HH:mm:ss')
        },
        upskillData?.id
      );
    }
    setLoading(false);

    if (!response?.success) {
      Object.keys(response?.data).map((key, i) => {
        const error = response?.data[key][0];
        setErrorMessage(error);
        return <li key={error}>{error}</li>;
      });
    }
    if (response?.success && response?.data) {
      setPageMessage(response?.message);
      localStorage.setItem(`upskill_${id}`, JSON.stringify(response?.data));
      window.location.reload();
    }
  };

  useEffect(() => {
    let data;
    if (artisanData) {
      data = JSON.parse(artisanData);
      setUpskillData(data?.upskilling_reports[0]);
    }
    if (storedAssessment) {
      data = JSON.parse(storedAssessment);
      setUpskillData(data);
    }
  }, [artisanData, storedAssessment]);

  useEffect(() => {
    reset(upskillData);
  }, [upskillData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loader />}
      <Grid container>
        <Grid item lg={12}>
          {' '}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}{' '}
          {errorMessage.length > 0 && (
            <Alert severity="error">{errorMessage}</Alert>
          )}{' '}
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            variant="standard"
            label="Skill"
            required={true}
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('skill', {
              required: {
                value: true,
                message: 'Field is required'
              }
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.skill?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            variant="standard"
            label="Officer Name"
            required={true}
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('officer_name', {
              required: {
                value: true,
                message: 'Field is required'
              }
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.officer_name?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <Controller
            control={control}
            name="dateInput"
            render={({ field }) => (
              <DatePicker
                placeholderText={'Enter Assessment Date'}
                onChange={(date) => field.onChange(date)}
                selected={
                  field.value
                    ? field.value
                    : Date.parse(upskillData?.upskilling_date)
                }
                maxDate={moment().toDate()}
                customInput={
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Upskilling Date"
                    {...register('dateInput', {
                      required: {
                        value: true,
                        message: 'Field is required'
                      }
                    })}
                  />
                }
              />
            )}
          />
          <Typography variant="small" component="small" color="error">
            {errors.dateInput?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            type="tel"
            label="Upskilling Venue"
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('upskilling_venue', {
              required: {
                value: true,
                message: 'email is required'
              }
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.upskilling_venue?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            label="Officer Comments"
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('officer_comments', {
              required: {
                value: true,
                message: 'Field is required'
              }
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.officer_comments?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            label=" Recommendations"
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('officer_recommendation', {
              required: {
                value: true,
                message: 'Field is required'
              }
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.officer_recommendations?.message}
          </Typography>
        </Grid>
        <Grid item lg={12} margin="12px" p={3}>
          {' '}
          <Button
            size="large"
            variant={loading ? 'variant' : 'contained'}
            fullWidth
            type="submit"
            // loading={loading}
            // loadingIndicator='Loading...'
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
