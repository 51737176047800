export const capitalizeInitialLetter = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  );
};

export const stringAvatar = (str) => {
  return {
    children: `${str.split(' ')[0][0].toUpperCase()}${str
      .split(' ')[1][0]
      .toUpperCase()}`
  };
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'KES'

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
