import { Navigate } from 'react-router-dom';
import { AuthForm } from '../components';
import { useAuth } from '../context/useAuth';

function Home () {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <AuthForm />
    </>
  );
}

export default Home;
