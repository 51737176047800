import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  TextField,
  Autocomplete,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import { fetchAllArtisans, fetchOneArtisan, fetchOneCustomer, reassignJob } from '../../api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from '../Loader';
import moment from 'moment';
import PropTypes from 'prop-types';

function SingleJobDetail(props) {
  const navigate = useNavigate();
  const { job } = props;
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [artisan, setArtisan] = useState([]);
  const [editArtisan, setEditArtisan] = useState(false);
  const [selectedArtisan, setSelectedArtisan] = useState('');

  const [availableArtisans, setAvailableArtisans] = useState([]);
  const userType = localStorage.getItem('user-role');

  const [openImage, setOpenImage] = useState(null);

  const OpenImage = (a) => {
    setOpenImage(a.url);
    window.open(a.url);
  };

  const handleClick = () => {
    const newWindow = window.open(
      'http://www.abcremovalsandstorage.com/wp-content/uploads/2015/09/Your_Essential_Checklist.png'
    );
    newWindow.print();
  };

  const checkCustomer = useCallback(
    async (id) => {
      setLoading(true);
      const response = await fetchOneCustomer(id);
      setLoading(false);

      if (response?.success && response?.data) {
        setCustomer(response?.data);
      }
    },
    [job]
  );

  const reassignArtisan = async () => {
    setLoading(true);
    console.log(job?.id, selectedArtisan);
    const response = await reassignJob(job?.id, selectedArtisan);

    setLoading(false);
    if (response?.success) {
      toast.success('Artisan assigned successfully');
      setEditArtisan(false);
      setTimeout(() => window.location.reload(), 1000);
    }
  };

  const handleChange = async (event, value) => {
    if (value) {
      const res = await fetchAllArtisans({ name: value, page: 1 });
      setAvailableArtisans(res?.data?.data || []);
    } else {
      const res = await fetchAllArtisans({ page: 1 });
      setAvailableArtisans(res?.data?.data || []);
    }
  };

  const checkArtisan = useCallback(async (id) => {
    setLoading(true);
    const response = await fetchOneArtisan(id);
    setLoading(false);

    if (response?.success && response?.data) {
      setArtisan(response?.data);
    }
  });

  useEffect(() => {
    if (job?.customer_id != null) {
      checkCustomer(job?.customer_id);
    }
    if (job?.artisan_id != null) {
      checkArtisan(job?.artisan_id);
    }
  }, [job?.customer_id, job?.artisan_id]);

  useEffect(() => {
   const getArtisans = async() => {
    const artisans = await fetchAllArtisans(1);
    setAvailableArtisans(artisans?.data?.data);
   };

   getArtisans();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Box>
        <ToastContainer />
        <Box alignItems="center" justifyContent="center" display="flex">
          <Typography
            id="modal-modal-title"
            variant="h5"
            fontWeight="bold"
            component="span"
            color="primary"
          >
            {job?.job_key}
          </Typography>
        </Box>

        <Grid container>
          <Grid item lg={8} sm={12}>
            <Typography variant="h6">
              Status:{' '}
              <Typography
                variant="p"
                color={
                  job?.status === 'accepted' ? 'status.success' : 'primary'
                }
              >
                {job?.status === 'accepted' ? 'Accepted' : job?.status}
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        <Grid lg={12}>
          <LinearProgress
            sx={{ borderRadius: 5, height: 2 }}
            value={'100%'}
            variant={'determinate'}
          />
        </Grid>

        <Grid container mt={1}>
          <Grid item lg={6} md={4} sm={12} display="flex" flexDirection="row">
            <Typography
              variant="p"
              color="primary"
              pt={3}
              pl={'3px'}
              sx={{ float: 'left' }}
            >
              Customer:
              <Typography color="secondary" variant="span" pl={1}>
                {customer?.first_name + ' ' + customer?.last_name ??
                  'Fetching Customer'}
              </Typography>
            </Typography>
          </Grid>
          <Grid item lg={6} md={8} sm={12} display="flex" flexDirection="row">
            {!editArtisan ? (
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Artisan:
                <Typography color="secondary" variant="span">
                  {(job?.artisan?.first_name !== null &&
                  job?.artisan?.first_name.length > 1)
                    ? job?.artisan?.first_name + ' ' + job?.artisan?.last_name
                    : 'Artisan not available'}
                </Typography>
              </Typography>
            ) : (
              <Box sx={{ display: 'flex', width: '100%', gap: '5px' }}>
                <Autocomplete
                  disablePortal
                autoHighlight
                fullWidth
                getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name}
                isOptionEqualToValue={(option, value) => option.first_name === value.first_name}
                options={availableArtisans}
                onChange={(event, newValue) => {
                  setSelectedArtisan(newValue.id);
                }}
                onInputChange={handleChange}
                renderOption={(props, option) => {
                  const { ...optionProps } = props;
                  return (
                    <Box
                      key={optionProps.id}
                      component="li"
                      {...optionProps}
                    >
                      {option.first_name} {option.last_name}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose an artisan"
                    variant="filled"
                    required
                    fullWidth
                    value={`${selectedArtisan?.first_name} ${selectedArtisan?.last_name}`}
                    slotProps={{
                      input: {
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      },
                    }}
                  />
                  )}
                />

                <Button onClick={reassignArtisan} disabled={!selectedArtisan} variant='outlined' fullWidth sx={{ maxWidth: '140px' }}>
                  Assign Artisan
                </Button>
              </Box>
          )}
            {(availableArtisans?.length && editArtisan === false) && (
              <Typography variant="p" color="primary" pt={2.5} pl={'4px'}>
                <EditIcon
                  onClick={() => setEditArtisan(true)}
                  color={userType === 'super-admin' ? 'primary' : 'secondary'}
                />
              </Typography>
            )}
          </Grid>
          <Grid item lg={6} sm={6} display="flex" flexDirection="row">
            <Typography
              variant="p"
              color="primary"
              pt={3}
              pl={'3px'}
              sx={{ float: 'left' }}
            >
              Description:
              <Typography color="secondary" variant="span" pl={1}>
                {job?.description || 'Not available'}
              </Typography>
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} display="flex" flexDirection="row">
            <Typography
              variant="p"
              color="primary"
              pt={3}
              pl={'3px'}
              sx={{ float: 'left' }}
            >
              Schedule:
              <Typography color="secondary" variant="span" pl={1}>
                {moment(job?.schedule).format('MMM DD, YYYY')}
              </Typography>
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} display="flex" flexDirection="row">
            <Typography
              variant="p"
              color="primary"
              pt={3}
              pl={'3px'}
              sx={{ float: 'left' }}
            >
              Service type:
              <Typography color="secondary" variant="span" pl={1}>
                {job?.service_type}
              </Typography>
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} display="flex" flexDirection="row">
            <Typography
              variant="p"
              color="primary"
              pt={3}
              pl={'3px'}
              sx={{ float: 'left' }}
            >
              Address:
              <Typography color="secondary" variant="span" pl={1}>
                {job?.address}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {job?.artisan && (
          <Accordion
            sx={{
              my: 2,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Artisan Details: </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container mt={1}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  display="flex"
                  flexDirection="row"
                >
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Email:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.email || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  display="flex"
                  flexDirection="row"
                >
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Artisan:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.first_name + ' ' + artisan?.last_name || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Country / State:
                    <Typography color="secondary" variant="span" pl={1}>
                      {(artisan?.profile?.country || 'N/A') +
                        ' / ' +
                        (artisan?.profile?.city || 'N/A')}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Business name:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.profile?.business_name || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Business RN:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.profile?.business_registration_number || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Years of experience:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.professional_training?.years_of_experience ||
                        'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Average rating:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.average_rating || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {job?.files?.length > 0 &&
          job?.files?.map((a, index) => {
            return (
              <>
                <Grid key={a.id} my={2}>
                  <Button
                    // pb={2}
                    variant="outlined"
                    sx={{ width: 300, float: 'right', borderRadius: 2 }}
                    my={2}
                    onClick={() => OpenImage(a)}
                  >
                    View Uploaded File {index + 1}
                  </Button>
                </Grid>
              </>
            );
          })}

        {job?.quotation && job?.quotation?.status === 'pending' && (
          <Accordion
            sx={{
              my: 2,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Quotation: </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container mt={1}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  display="flex"
                  flexDirection="row"
                >
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Reference:
                    <Typography color="secondary" variant="span" pl={1}>
                      {job?.quotation?.reference || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  display="flex"
                  flexDirection="row"
                >
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Status:
                    <Typography color="secondary" variant="span" pl={1}>
                      {job?.quotation?.status || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Created date:
                    <Typography color="secondary" variant="span" pl={1}>
                      {moment(job?.quotation?.created_at).format(
                        'MMM DD, YYYY'
                      ) || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Updated date:
                    <Typography color="secondary" variant="span" pl={1}>
                      {moment(job?.quotation?.updated_at).format(
                        'MMM DD, YYYY'
                      ) || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
              <Button> Reassign Job</Button>
            </AccordionDetails>
          </Accordion>
        )}
        {job?.invoice && job?.quotation?.status === 'accepted' && (
          <Accordion
            sx={{
              my: 2,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Invoice: </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container mt={1}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  display="flex"
                  flexDirection="row"
                >
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Reference:
                    <Typography color="secondary" variant="span" pl={1}>
                      {job.invoice.reference || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  display="flex"
                  flexDirection="row"
                >
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Status:
                    <Typography color="secondary" variant="span" pl={1}>
                      {job.invoice.status || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Created date:
                    <Typography color="secondary" variant="span" pl={1}>
                      {moment(job.invoice.created_at).format('MMM DD, YYYY') ||
                        'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Updated date:
                    <Typography color="secondary" variant="span" pl={1}>
                      {moment(job.invoice.updated_at).format('MMM DD, YYYY') ||
                        'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </>
  );
}

SingleJobDetail.propTypes = {
  job: PropTypes.object,
  allArtisans: PropTypes.array,
};

export default SingleJobDetail;
