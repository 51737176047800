import { createContext, useContext, useEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';

const AuthContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const location = useLocation();

  function login (user) {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  function logout () {
    setUser(null);
    localStorage.clear();
  }

  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
      }
    } catch (error) {

    }
  }, [location]);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
