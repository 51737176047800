import { useState, useEffect } from 'react';
import {
  Alert,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { loginCustomer } from '../../api';
import { useAuth } from '../../context/useAuth';
import phone from 'phone';

function SignInForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState('email');

  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [signInError, setSignInError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    setRadioValue(value);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    const response = await loginCustomer({
      ...data,
      username:
        radioValue === 'phone'
          ? phone(`+254-${data.phonenumber}`).phoneNumber
          : data.email,
    });
    if (response.error) {
      const { error } = response;
      setSignInError(error.message);
      setLoading(false);
      return;
    }

    if (response.errors?.password) {
      const { password } = response.errors;
      setSignInError(password[0]);
      return;
    }

    const { token, user } = response.data;
    setLoading(false);
    auth.login(user);

    localStorage.setItem('token', JSON.stringify(token.access_token));
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('user-role', JSON.stringify(user.roles[0].name));

    if (user.roles[0].name !== 'assessor') {
      navigate('/dashboard', { replace: true });
    } else {
      navigate('/dashboard/assessments', { replace: true });
    }
  };

  useEffect(() => {
    const alert = setTimeout(() => setSignInError(false), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [signInError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid mt={4} mb={2} sx={{ backgroundColor: 'white' }}>
        {/* <Typography>
          Please select from the options below, you preferred login method
        </Typography> */}
        <FormControl mb={2}>
          <FormLabel id="demo-radio-buttons-group-label">
            {' '}
            Please select from the options below, you preferred login method
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="email"
            name="radio-buttons-group"
            row
            onChange={(e) => handleChange(e)}
          >
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="phone" control={<Radio />} label="Phone" />
          </RadioGroup>
        </FormControl>
        {radioValue === 'email' ? (
          <>
            <TextField
              label="Email"
              variant="standard"
              fullWidth
              {...register('email', {
                required: {
                  value: true,
                  message: 'email is required',
                },

                validate: (value) => validator.isEmail(value),
              })}
              required={!!errors.email}
            />
            <Typography variant="small" component="small" color="error">
              {errors.email?.message}
              {errors.email &&
                errors.email.type === 'validate' &&
                'Enter a valid email address'}
            </Typography>{' '}
          </>
        ) : (
          <>
            <TextField
              type="tel"
              label="Phone Number"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+254</InputAdornment>
                ),
              }}
              fullWidth
              {...register('phonenumber', {
                required: {
                  value: true,
                  message: 'Phone number is required',
                },
                maxLength: {
                  value: 10,
                  message: 'Phone number cannot exceed 10 digits',
                },
                validate: (value) =>
                  validator.isMobilePhone(value) &&
                  phone(`+254-${value}`).isValid,
              })}
              required={!!errors.phonenumber}
            />
            <Typography variant="small" component="small" color="error">
              {errors.phonenumber?.message}
              {errors.phonenumber &&
                errors.phonenumber.type === 'validate' &&
                'Enter a valid phone number'}
            </Typography>
          </>
        )}
      </Grid>
      <Grid mb={2}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Password"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          {...register('password', {
            required: { value: true, message: 'Password cannot be empty' },
          })}
          required={!!errors.password}
        />
        <Typography variant="small" component="small" color="error">
          {errors.password?.message}
        </Typography>
      </Grid>
      {signInError && <Alert severity="error">{signInError}</Alert>}
      <Grid mb={6}>
        {/* <MUILink
          variant="body2"
          underline="none"
          component={Link}
          to="/forgot-password"
          color="inherit"
          py={1}
        >
          Forgot Password?
        </MUILink> */}
      </Grid>
      <Grid mb={3}>
        <LoadingButton
          size="large"
          variant={loading ? 'variant' : 'contained'}
          fullWidth
          type="submit"
          loading={loading}
          loadingIndicator="Loading..."
        >
          Sign In
        </LoadingButton>
      </Grid>
    </form>
  );
}

export default SignInForm;
