import axios from 'axios';

export async function addNewLocation (data) {
  try {
    const response = await axios.post('/admin/locations', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function deleteLocation (row) {
  const data = {
    name: row.name
  };
  try {
    const response = await axios.delete(
      `/admin/locations/${row.id}`,
      { data }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function deleteRequestType (row) {
  const data = {
    name: row.name
  };
  try {
    const response = await axios.delete(
      `/admin/request-types/${row.id}`,
      { data }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function addNewRequestType (data) {
  try {
    const response = await axios.post('/admin/request-types', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function updateLocation (id, data) {
  try {
    const response = await axios.put(`/admin/locations/${id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function updateRequestType (id, data) {
  try {
    const response = await axios.put(
      `/admin/request-types/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message
      },
      errors: data.data
    };
  }
}
