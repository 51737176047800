import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import ExpandCircleDownOutlined from '@mui/icons-material/ExpandCircleDownOutlined';
import moment from 'moment';

export default function index(props) {
    const { dateRange = [] } = props;
    const groupedByDate = dateRange.reduce((acc, slot) => {
        const date = slot.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(slot);
        return acc;
    }, {});

    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Current Slot Arrangement</Typography>
            {Object.entries(groupedByDate).map(([date, slots]) => (
                <Accordion key={date}>
                    <AccordionSummary expandIcon={<ExpandCircleDownOutlined />}>
                        <Typography>{moment(date).format('dddd, MMMM D, YYYY')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            {slots.map((slot) => (
                                <Grid item xs={12} sm={6} md={4} key={slot.id}>
                                    <Box
                                        sx={{
                                            p: 1,
                                            border: 1,
                                            borderColor: 'primary.main',
                                            borderRadius: 1,
                                            bgcolor: '#fff',
                                            color: 'primary.dark',
                                        }}
                                    >
                                        <Typography variant="body2">
                                            {moment(slot.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(slot.end_time, 'HH:mm:ss').format('HH:mm')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
