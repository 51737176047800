import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Card,
  Alert,
  AlertTitle,
  CardContent
} from '@mui/material';
import { Loader, Location, RequestTypes } from '../components';
import {
  getLocations,
  getRequestTypes,
  addNewLocation,
  addNewRequestType
} from '../api';
import { AddCircle } from '@mui/icons-material';

function Index () {
  const [locations, setLocations] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [toggleType, setToggleType] = useState('location');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageError, setPageError] = useState('');
  const [pageMessage, setPageMessage] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    getLocations().then((data) => {
      setLocations(data);
    });

    getRequestTypes().then((data) => {
      setRequestTypes(data);
    });
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleAddMore = () => {
    setOpen(true);
  };

  const addMore = async (data) => {
    setLoading(true);
    if (toggleType === 'location') {
      const response = await addNewLocation(data);
      setLoading(false);

      if (response?.error) {
        const { error } = response;
        setPageError(error?.message);
        return;
      }

      if (!response?.success || response?.message) {
        setPageError(response?.message);
      }
      if (response?.success) {
        setPageMessage('Successfully created a new location');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      const response = await addNewRequestType(data);
      setLoading(false);

      if (response?.error) {
        const { error } = response;
        setPageError(error?.message);
        return;
      }

      if (!response?.success || response?.message) {
        setPageError(response?.message);
      }
      if (response?.success) {
        setPageMessage('Successfully created a new request type');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      setToggleType('requestType');
    }
    setOpen(false);
  };

  return (
    <>
      {loading && <Loader />}

      <Grid minHeight="100vh" container>
        <Grid item sm={8} lg={7} mt={{ lg: 4 }} marginX="auto" container p={3}>
          {/* <Grid item lg={6} mb={1}>
            <ToggleButtonGroup
              color='primary'
              value={toggleType}
              onChange={(event, newServiceType) => {
                if (newServiceType !== null) {
                  setToggleType(newServiceType)
                }
              }}
              exclusive
            >
              <ToggleButton value='location'>Location</ToggleButton>
              <ToggleButton value='requestType'>Request Type</ToggleButton>
            </ToggleButtonGroup>
          </Grid> */}
          {pageMessage && (
            <Alert severity="success" container>
              <AlertTitle>Success</AlertTitle>
              <strong>{pageMessage}</strong>
            </Alert>
          )}
          <Box width="100%" minHeight="90vh">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={4}
            >
              <ToggleButtonGroup
                color="primary"
                value={toggleType}
                onChange={(event, newServiceType) => {
                  if (newServiceType !== null) {
                    setToggleType(newServiceType);
                  }
                }}
                exclusive
              >
                <ToggleButton value="location">Location</ToggleButton>
                <ToggleButton value="requestType">Request Type</ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="outlined"
                fontWeight="bold"
                onClick={() => handleAddMore()}
                p={2}
                h={20}
              >
                <AddCircle /> Add More
              </Button>
            </Box>

            {toggleType === 'location' && <Location locations={locations} />}
            {toggleType === 'requestType' && (
              <RequestTypes requestTypes={requestTypes} />
            )}
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}>
          {pageError && <Alert severity="error">{pageError}</Alert>}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
          <Grid mb={1}>
            {toggleType === 'location'
              ? (
              <Typography
                id="modal-modal-title"
                variant="h6"
                fontWeight="bold"
                component="span"
                color="primary"
              >
                Add New Location
              </Typography>
                )
              : (
              <Typography
                id="modal-modal-title"
                variant="h6"
                fontWeight="bold"
                component="span"
                color="primary"
              >
                Add New Request Type
              </Typography>
                )}
          </Grid>
          <form onSubmit={handleSubmit(addMore)}>
            <CardContent sx={{ padding: 5 }}>
              <TextField
                variant="standard"
                label={toggleType === 'location' ? 'Location' : 'Request Type'}
                required={
                  errors.location ? true : !!errors.requestType
                }
                sx={{ width: '60%' }}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Field is required'
                  }
                })}
              />
              <Box mt={1}>
                <Typography variant="small" component="small" color="error">
                  {' '}
                  {errors.location?.message || errors?.requestType?.message}
                </Typography>
              </Box>
              {toggleType === 'requestType' && (
                <div>
                  <TextField
                    variant="standard"
                    label="Description"
                    required={true}
                    sx={{ width: '60%' }}
                    {...register('description', {
                      required: {
                        value: true,
                        message: 'Field is required'
                      }
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.location?.message || errors?.requestType?.message}
                    </Typography>
                  </Box>
                </div>
              )}
            </CardContent>
            <Grid mt={3}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: 300, margin: 'auto' }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Card>
      </Modal>
    </>
  );
}

export default Index;
