/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Modal,
  Grid,
  Button,
} from '@mui/material';
import { fetchAllAssessors, getAvailableTimeSlots } from '../api';
import { AssessorModal, DataGrid, DateRangeComponent } from '../components';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';

function Index () {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [openTimeSlotDetails, setOpenTimeSlotDetails] = useState(false);
  const handleCloseTimeSlotDetails = () => setOpenTimeSlotDetails(false);
  const [dateRange, setDateRange] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left'
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2
  };

  const getAllAssessors = async (page) => {
    setLoading(true);
    const response = await fetchAllAssessors({ page: page + 1 });
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((assessorData) => ({
        ...assessorData,
        name: `${assessorData?.first_name} ${assessorData?.last_name}`,
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getTimeSlots = async () => {
    const response = await getAvailableTimeSlots();
    setDateRange(response?.data);
  };

  const getPage = async (page) => {
    await fetchAllAssessors(page);
    setPage(page);
  };

  const handleOpenTimeSlotDetails = () => {
    setOpenTimeSlotDetails(true);
  };

  useEffect(() => {
    getAllAssessors(1);
  }, []);

  useEffect(() => {
    getTimeSlots();
  }, []);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  const columns = [
    { field: 'name', headerName: 'Name', width: 250 },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 200
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250
    },
    {
      field: 'is_active',
      headerName: 'Active Status',
      width: 160
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Grid container justifyContent='space-between'>
        <Grid item lg={6}>
          <Typography variant="h5" mb={3}>
            All Assessors
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Button
            variant="outlined"
            sx={{ width: 300, float: 'right' }}
            onClick={handleOpenTimeSlotDetails}
          >
            {' '}
            Generate time slots{' '}
          </Button>
        </Grid>
      </Grid>

      <Box width="100%">
      <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={(row) => console.log(row)}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
        <Modal
          open={openTimeSlotDetails}
          onClose={handleCloseTimeSlotDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <AssessorModal />
          </Box>
        </Modal>
      </Box>

      <Box width="100%">
        { dateRange.length > 0 && <DateRangeComponent dateRange={dateRange} /> }
      </Box>
    </Box>
  );
}

export default Index;
