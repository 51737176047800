import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import LogoImage from '../../assets/images/fundis-logo.png';

function Logo () {
  return (
    <>
      <Grid
        direction="column"
        maxWidth="max-content"
        container
        sx={{ marginLeft: 2 }}
      >
        <Link to="/">
          <Grid component="span" item>
            <img src={LogoImage} alt="Fundis Logo" />
          </Grid>
        </Link>
        <Typography variant="h6" component="span" fontWeight="bold">
          FUNDIS
        </Typography>
      </Grid>
    </>
  );
}

export default Logo;
