import axios from 'axios';

export async function createJobRequest (jobRequestData) {
  const formData = new FormData();

  for (let i = 0; i < jobRequestData.length; i++) {
    const request = jobRequestData[i];

    formData.append(`job_request[${i}][service_type]`, request.service_type);
    formData.append(
      `job_request[${i}][request_type_id]`,
      request.request_type_id
    );
    formData.append(`job_request[${i}][schedule]`, request.schedule);
    formData.append(`job_request[${i}][description]`, request.description);
    formData.append(`job_request[${i}][location_id]`, request.location_id);
    formData.append(`job_request[${i}][address]`, request.location_address);
    formData.append(`job_request[${i}][file]`, request.file);
  }

  try {
    const response = await axios({
      method: 'post',
      url: '/customer/job-request',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getJobRequests () {
  try {
    const response = await axios({
      method: '',
      url: '/request-types'
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllJobRequests ({ pageNo = 1, name = '', locationId = '', status = '', reference = '', skillId = '', serviceType = '' }) {
  console.log(reference);
  let url = `/admin/job-request?page=${pageNo}`;
  if (locationId) url += `&location_id=${locationId}`;
  if (name) url += `&name=${name}`;
  if (status) url += `&status=${status}`;
  if (reference) url += `&reference=${reference}`;
  if (skillId) url += `&skill_id=${skillId}`;
  if (serviceType) url += `&service_type=${serviceType}`;

  try {
    const response = await axios({
      method: '',
      url
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchOneJobRequest (id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/job-request/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchJobRequestPagination (url) {
  try {
    const response = await axios({
      method: '',
      url
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchJobRequest (userId) {
  try {
    const response = await axios({
      method: '',
      url: `/customer/job-request/${userId}`
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getRequestTypes () {
  try {
    const response = await axios('/request-types');
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getLocations () {
  try {
    const response = await axios('/locations');
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
}
